@import '../../style/themes/index';
@import '../../style/mixins/index';

@collapse-prefix-cls: ~'@{ant-prefix}-collapse';

.@{collapse-prefix-cls} {
  .reset-component;

  background-color: @collapse-header-bg;
  border: @border-width-base @border-style-base @border-color-base;
  border-bottom: 0;
  border-radius: @collapse-panel-border-radius;

  & > &-item {
    border-bottom: @border-width-base @border-style-base @border-color-base;

    &:last-child {
      &,
      & > .@{collapse-prefix-cls}-header {
        border-radius: 0 0 @collapse-panel-border-radius @collapse-panel-border-radius;
      }
    }

    > .@{collapse-prefix-cls}-header {
      position: relative;
      padding: @collapse-header-padding;
      padding-left: @collapse-header-padding-extra;
      color: @heading-color;
      line-height: 22px;
      cursor: pointer;
      transition: all 0.3s;

      .@{collapse-prefix-cls}-arrow {
        .iconfont-mixin();

        position: absolute;
        top: 50%;
        left: @padding-md;
        display: inline-block;
        font-size: @font-size-sm;
        transform: translateY(-50%);

        & svg {
          transition: transform 0.24s;
        }
      }

      .@{collapse-prefix-cls}-extra {
        float: right;
      }

      &:focus {
        outline: none;
      }
    }

    &.@{collapse-prefix-cls}-no-arrow {
      > .@{collapse-prefix-cls}-header {
        padding-left: 12px;
      }
    }
  }

  // Expand Icon right
  &-icon-position-right {
    & > .@{collapse-prefix-cls}-item {
      > .@{collapse-prefix-cls}-header {
        padding: @collapse-header-padding;
        padding-right: @collapse-header-padding-extra;

        .@{collapse-prefix-cls}-arrow {
          right: @padding-md;
          left: auto;
        }
      }
    }
  }

  &-anim-active {
    transition: height 0.2s @ease-out;
  }

  &-content {
    overflow: hidden;
    color: @text-color;
    background-color: @collapse-content-bg;
    border-top: @border-width-base @border-style-base @border-color-base;

    & > &-box {
      padding: @collapse-content-padding;
    }

    &-inactive {
      display: none;
    }
  }

  &-item:last-child {
    > .@{collapse-prefix-cls}-content {
      border-radius: 0 0 @collapse-panel-border-radius @collapse-panel-border-radius;
    }
  }

  &-borderless {
    background-color: @collapse-header-bg;
    border: 0;
  }

  &-borderless > &-item {
    border-bottom: 1px solid @border-color-base;
  }

  &-borderless > &-item:last-child,
  &-borderless > &-item:last-child &-header {
    border-radius: 0;
  }

  &-borderless > &-item > &-content {
    background-color: transparent;
    border-top: 0;
  }

  &-borderless > &-item > &-content > &-content-box {
    padding-top: 4px;
  }

  & &-item-disabled > &-header {
    &,
    & > .arrow {
      color: @disabled-color;
      cursor: not-allowed;
    }
  }
}

@border-color-base: #d9d9d9;@border-radius-base: 2px;@disabled-color: rgba(0, 0, 0, 0.25);@error-color: #f5222d;@font-size-base: 14px;@heading-color: rgba(0, 0, 0, 0.85);@link-color: #4e57d3;@primary-color: #4e57d3;@success-color: #52c41a;@text-color: rgba(0, 0, 0, 0.65);@text-color-secondary: rgba(0, 0, 0, 0.45);@warning-color: #faad14;